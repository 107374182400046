<template>
    <section class="vigik3">
        <services-list />
    </section>
</template>

<script>
import ServicesList from "@/views/vigik/accessType/ServicesList"
export default {
    name: "Vigik3",
    components: {
        ServicesList,
    },
}
</script>

<style scoped lang="scss">
.vigik3 {
    width: 100%;
    height: 100%;
    margin-top: 20px;
}
</style>
